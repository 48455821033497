@mixin button {
  @include transition(border 0.2s ease, background 0.2s ease, color 0.2s ease);
  @include border-radius(23px);

  background: $blue-blue;
  border: 1px solid $blue-blue;
  display: inline-block;
  border-spacing: 0;
  color: $white;
  font-family: inherit;
  font-weight: 600;
  line-height: em(17px);
  list-style: none outside none;
  height: auto;
  margin: 0;
  padding: 14px 25px 12px;
  font-size: em(13.5px);
  max-width: 100%;
  text-decoration: none;
  text-indent: 0;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 1.8px;
  position: relative;
  cursor: pointer;

  &::-moz-focus-inner,
  &::-moz-focus-inner {
    border: 0;
    padding: 0;
  }

  &:hover {
    background: $medium-blue;
    border: 1px solid $medium-blue;
  }

  &.small {
    font-size: em(13px);
    line-height: em(16px);
    padding: 13px 20px 9px;
  }

  &.ultra-small {
    font-size: em(10.5px);
    line-height: em(15px);
    padding: 12px 20px 9px;
    letter-spacing: 0.88px;
  }

  &.second {
    background-color: $white;
    color: $blue-blue;
    border: 1px solid $blue-blue;
    font-weight: 600;

    &:hover {
      color: $dark-blue;
      border: 1px solid $dark-blue;
    }
  }

  &.bordered.inverse {
    background: transparent;
    color: $white;
    border: 1px solid $white;
    transition: all 0.2s ease;

    .arrow {
      transition: all 0.2s ease;
      fill: $white;
    }

    &:hover {
      background: rgba(255, 255, 255, 0.1);
    }
  }

  &.alt {
    background-color: $watermelon;
    color: $white;
    border: 1px solid $watermelon;

    &:hover {
      background-color: darken($watermelon, 7%);
      border-color: darken($watermelon, 7%);
    }
  }

  &.loading {
    position: relative;
    cursor: default;
    text-indent: -9999px;
    white-space: nowrap;

    &:before {
      @include border-radius(50%);
      @include animation(spinner 1.2s linear infinite);

      content: '';
      position: absolute;
      height: 16px;
      width: 16px;
      top: calc(50% - (21px / 2));
      left: calc(50% - (19px / 2));
      border: 2px solid $white;
      border-top-color: transparent;
    }

    &.light:before {
      border-color: $secondary;
      border-top-color: transparent;
    }

    &.second:before {
      border-color: #fff;
      border-top-color: transparent;
    }

    &.second.light:before {
      border-color: $tertiary;
      border-top-color: transparent;
    }

    .arrow {
      display: none !important;
    }
  }

  &.checked {
    background: $primary;
    position: relative;
    text-indent: -9999px;
    white-space: nowrap;

    &:before {
      @include rotate(45deg);

      content: '';
      position: absolute;
      top: calc(50% - (23px / 2));
      left: calc(50% - (7px / 2));
      display: inline-block;
      width: 5px;
      height: 17px;
      border-right: 2px solid #fff;
      border-bottom: 2px solid #fff;
    }

    &:hover {
      border: 1px solid $primary;
    }

    &.second {
      background: $white;

      &:before {
        border-right: 2px solid $primary;
        border-bottom: 2px solid $primary;
      }

      &:hover {
        border: 1px solid $dark-blue;

        &:before {
          border-right: 2px solid $dark-blue;
          border-bottom: 2px solid $dark-blue;
        }
      }
    }
  }

  &.disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

  @include keyframes(spinner) {
    0% {
      @include rotateZ(0);
    }

    100% {
      @include rotateZ(360deg);
    }
  }

  &.with-arrow {
    padding-right: 50px;

    span.arrow {
      position: absolute;
      top: 21px;
      right: 25px;
      display: block;
      width: 13px;
      height: 2px;
      background-color: $white;

      &::before,
      &::after {
        position: absolute;
        right: -1px;
        display: block;
        content: '';
        height: 2px;
        width: 7px;
        background-color: $white;
      }

      &::before {
        bottom: 2px;
        transform: rotate(45deg);
      }

      &::after {
        top: 2px;
        transform: rotate(-45deg);
      }
    }

    svg.arrow {
      position: absolute;
      fill: $white;
      top: calc(50% - 8px);
      right: 16px;
      display: block;
      width: 16px;
      height: 16px;
    }

    &.second {
      span.arrow {
        background-color: $blue-blue;

        &::before,
        &::after {
          background-color: $blue-blue;
        }
      }

      svg.arrow {
        fill: $blue-blue;
      }

      &:hover {
        span.arrow {
          background-color: $dark-blue;

          &::before,
          &::after {
            background-color: $dark-blue;
          }
        }

        svg.arrow {
          fill: $dark-blue;
        }
      }
    }

    &.small {
      padding-right: 42px;

      span.arrow {
        top: 18px;
      }

      svg.arrow {
        top: 11px;
      }
    }
  }

  .arrow {
    display: none;
  }
}

input[type='submit'],
input[type='button'],
.button,
button:not(.mfp-close) {
  &:not(.Button):not(.ButtonIcon) {
    @include button;
  }
}
