.schema {
	position: relative;
	@extend .clearfix;
	padding: 30px 0 40px;

	@media #{$phoneL} {
		padding-top: 0;
	}

	&:before {
		content: '';
		position: absolute;
		top: 0;
		bottom: 0;
		left: 50%;
		@include translate(-50%, 0);
		width: 1px;
		height: 100%;
		border-right: 0.5px solid $blue-blue;
		z-index: 1;
		@media #{$phoneL} {
			display: none;
		}
	}

	h2 {
		width: calc(50% - 80px);
		margin: -30px 0 30px;
		padding: 0;
		text-align: center;
		color: $blue-blue;
		font-weight: 600;
		line-height: em(55px);
		font-size: em(40px);

		strong {
			display: block;
			font-size: em(16px);
			line-height: em(22px);
			color: $dark-blue;
			text-transform: uppercase;
		}

		@media #{$phoneL} {
			display: none;
		}
	}

	.step {
		position: relative;
		z-index: 2;
		clear: both;
		text-align: center;
		@extend .clearfix;
		overflow: hidden;
		margin-bottom: 50px;
		padding: 0;

		@media #{$phoneL} {
			margin-bottom: 30px;
		}

		&:last-of-type {
			margin-bottom: 0;
		}
		& > p {
			text-transform: uppercase;
			margin: 0 0 10px 0;
			padding: 0;
		}
		& > em {
			display: inline-block;
			margin: 0 0 10px 0;
			padding: 0;
		}
		& > a.button {
			margin-bottom: 10px;
		}
		.icon {
			width: 70px;
			height: 70px;
			display: block;
			margin: 0 auto 20px;
			padding: 0;
			border-radius: 50%;
			border: 0.5px solid $blue-blue;
			background-color: $white;
			background-position: center;
			background-repeat: no-repeat;
			background-size: 30px;

			&.pen {
				background-image: url('../static/images/howitworks/pen.svg');
			}
			&.customer {
				background-image: url('../static/images/howitworks/customer.svg');
			}
			&.mail {
				background-image: url('../static/images/howitworks/mail.svg');
			}
			&.text {
				background-image: url('../static/images/howitworks/text.svg');
			}
			&.key {
				background-image: url('../static/images/howitworks/key.svg');
			}
			&.check {
				background-image: url('../static/images/howitworks/check.svg');
			}
		}

		.box {
			width: 50%;
			border: 0.5px solid $light-blue-grey;
			padding: 30px;
			margin-bottom: 20px;
			background: $white;
			text-align: left;
			color: $dark-blue-grey;

			@media #{$phoneL} {
				width: 100%;
				&:before {
					display: none;
				}
			}
			h3 {
				margin: 0 0 10px 0;
				padding: 0;
				text-transform: uppercase;
				font-size: em(18px);
				line-height: em(28px);
				color: $dark-blue;
				letter-spacing: 1.8px;
			}
			p {
				margin: 0 0 10px 0;
				padding: 0;
			}
			ul {
				margin: 0 0 10px 0;
				padding: 0;
				list-style: none;

				li {
					position: relative;
					margin: 0;
					padding: 0 0 5px 15px;
					list-style: none;

					&:before {
						content: '';
						position: absolute;
						top: calc(50% - 5px);
						left: 0;
						display: inline-block;
						width: 4px;
						height: 4px;
						background-color: $dark-blue;
					}
				}
			}
			em {
				font-size: em(14px);
			}
			p:last-of-type {
				margin-bottom: 0;
			}
			&.centered {
				margin: 0 auto 20px;

				@media #{$phoneL} {
					margin: 0 auto 10px;;
				}
			}
		}

		&.twin {
			padding: 0;

			.icon {
				@include translate(-50%, 0);
				position: absolute;
				top: 0;
				left: 50%;
			}

			.step-twin-child {
				position: relative;
				width: calc(50% - 80px);

				@media #{$tab-portrait} {
					width: calc(50% - 50px);
				}

				@media #{$phoneL} {
					width: 100%;

					&.left {
						margin-bottom: 30px;
					}
				}

				.box {
					width: 100%;

					@media #{$phoneL} {
						margin-bottom: 10px;
					}
				}
			}
		}
	}
}

.schema-end {
	text-align: center;
	padding-bottom: 110px;
}

@media #{$phoneL} {
	.process {
		.schema {
			.step, .step-twin-child {
				margin-bottom: 0 !important;
			}
		}
	}
}
