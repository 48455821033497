#MainContainer {
  position: relative;
  height: auto !important;
  z-index: 1;
  background-image: linear-gradient(to bottom, $warm-blue 33%, #ebf0fd 90%, #fff 97%);

  @include sm {
    background-image: linear-gradient(to bottom, $warm-blue 33%, #ebf0fd 83%, #fff 94%);
  }

  .promoBanner {
    position: relative;
    display: flex;
    justify-content: center;
    background: $light-grey;
    z-index: 2;
    padding: 15px 30px;
    gap: 10px;

    .icon {
      img {
        height: 25px;
        width: 25px;
      }
    }

    p {
      margin: 0;
      color: $dark-blue;

      span {
        color: $watermelon;
        font-weight: 600;
      }
    }
  }

  .newsletterSubscription {
    background: $light-grey;
    position: relative;
    z-index: 2;
    display: none;

    @include lg {
      display: block;
    }

    .boxed {
      @extend .clearfix;

      display: flex;
      flex-wrap: wrap;
      align-items: center;
      padding-top: 12px !important;
      padding-bottom: 12px !important;

      p {
        flex: 1 1 auto;
        margin: 0;
        padding: 0 0 0 60px;
        color: $dark;
        background: url('../static/images/icons/newsletter-icon.svg') center left no-repeat;

        strong {
          color: $dark-blue;
        }
      }

      form {
        flex: 0 0 auto;
        margin: 5px 0;
        min-width: 380px;
        text-align: right;

        @media #{$tab-portrait} {
          text-align: left;
        }
        @media #{$phoneL} {
          flex: 1 1 auto;
          text-align: center;
          min-width: auto;
          input[type='submit'] {
            margin: 5px 0 0;
          }
        }

        input[type='email'] {
          border: 1px solid #dae4eb;
          height: 40px;
          width: 240px;

          &:focus {
            border-color: $blue-blue;
          }
        }

        input[type='submit'] {
          margin-left: 5px;
          font-size: em(11px);
          line-height: em(15px);
          padding: 14px 20px 11px;
          letter-spacing: 1px;
        }
      }

      .newsletter-info {
        flex-grow: 1;
        position: relative;
        font-size: 13px;
        text-align: right;
        font-weight: 500;

        &.success {
          color: #008577;
        }

        &.error {
          color: #eb0033;
        }
      }
    }
  }

  .bluetransferBanner {
    background: $light-grey;
    position: relative;
    z-index: 2;

    .boxed {
      @extend .clearfix;

      display: flex;
      flex-wrap: wrap;
      align-items: center;
      padding-top: 12px !important;
      padding-bottom: 12px !important;

      p {
        flex: 1 1 auto;
        margin: 0;
        padding: 0 0 0 60px;
        color: $dark;
        background: url('../static/images/icons/bluetransfer-icon.svg') center left no-repeat;
        background-size: 40px;

        strong {
          color: $dark-blue;
        }
      }

      .bluetransfer-link {
        margin-left: 5px;
        padding: 14px 20px 11px;
        font-size: em(11px);
        line-height: em(15px);
        letter-spacing: 1px;
        color: white;
      }
    }
  }

  .informationsBanner {
    background: $light-grey;
    position: relative;
    z-index: 2;

    .boxed {
      @extend .clearfix;

      display: flex;
      align-items: center;
      padding-top: 12px !important;
      padding-bottom: 12px !important;

      img.bannerIcon {
        width: 40px;
        margin-right: 20px;
      }

      p {
        margin: 0 10px 0 0;
        color: $dark;
        line-height: 22px;
        flex: 1 1;

        strong {
          color: $dark-blue;
          font-weight: 600;
        }

        a {
          font-weight: 500;
        }
      }

      .button {
        margin-left: auto;
        background: $watermelon;
        border-color: $watermelon;
      }

      @media #{$phoneL} {
        flex-wrap: wrap;
        img.bannerIcon {
          display: none;
        }
        p {
          flex: 1 1 auto;
          margin: 0;
        }
        .button {
          margin: 6px auto 0;
        }
      }
    }
  }

  .more {
    padding: 50px 0 10px 0;
    text-align: center;
    cursor: pointer;

    @media #{$tab-portrait} {
      display: none;
    }

    p {
      margin: 0;
      padding: 0;
      font-size: em(11px);
      font-weight: 700;
      text-transform: uppercase;
      color: $white;
      letter-spacing: 1px;
    }

    svg {
      width: 12px;
      height: 10px;
      fill: $white;
      transform: rotate(90deg);
    }
  }
}
