.customerReviews {
  @extend .clearfix;
  padding: 0 60px;
  position: relative;
  @media #{$phoneL} {
    padding: 0;
  }
  .slider {
    overflow: hidden;
    border-bottom: 1px solid $tertiary;
    margin: 0 0 15px;
    position: relative;
  }
  .arrowLeft,
  .arrowRight {
    @include translate(0, -50%);
    @include opacity(.75);
    position: absolute;
    width: 23px;
    height: 52px;
    top: 50%;
    background-position: center;
    background-repeat: no-repeat;
    cursor: pointer;
    &:hover {
      @include opacity(1);
    }
    @media #{$phoneL} {
      display: none !important;
    }
  }
  .arrowLeft {
    left: 0;
    background-image: url('../static/images/icons/arrow-left.png');
  }
  .arrowRight {
    right: 0;
    background-image: url('../static/images/icons/arrow-right.png');
  }
  .dots {
    text-align: center;
    @media #{$phoneL} {
      display: none;
    }
    .dot {
      @include border-radius(50%);
      width: 10px;
      height: 10px;
      cursor: pointer;
      display: inline-block;
      margin: 0 10px 0 0;
      background-color: #bbbbbb;
      &:last-of-type {
        margin: 0;
      }
      &.active {
        background-color: $primary;
      }
    }
  }
  ul {
    @extend .clearfix;
    @include transition( left .2s ease );
    list-style: none;
    padding: 0;
    margin: 0;
    left: 0;
    position: absolute;
    li {
      float: left;
      margin-bottom: 20px;
      h4 {
        font-size: em(18px);
        margin: 0 0 20px 0;
        span {
          font-size: em(13px);
          color: $secondary;
          font-weight: normal;
        }
        img {
          margin-top: 5px;
          float: right;
          height: 15px;
        }
      }
      p {
        margin: 0;
      }
    }
  }
  a {
    text-transform: uppercase;
    position: absolute;
    bottom: 0;
    right: 60px;
    @media #{$phoneL} {
      float: right;
      position: relative;
      bottom: auto;
      right: auto;
    }
  }
}
.customerReviewsForm {

  fieldset {
    padding: 60px 20px 35px;
    border: 1px solid $light-blue-grey;

    legend {
      font-size: em(18px);
      color: $dark-blue;
      text-align: center;
      text-transform: uppercase;
      font-weight: bold;
      padding: 0 20px;
      letter-spacing: 1.8px;
      margin: auto;
    }

    .content {
      max-width: 560px;
      margin: 0 auto;

      h2 {
        margin-top: 40px;
        color: $dark-blue;
        font-weight: 600;
        text-transform: uppercase;
        font-size: em(11px);
        letter-spacing: 1px;
      }

      > p {
        font-size: em(15px);
      }

      .border-block {
        border: 1px solid $light-blue-grey;
        border-radius: 1px;
      }

      .stars {
        padding: 0 0 30px;
        text-align: center;
        margin-bottom: 50px;

        ul {
          display: inline-block;
          margin: 0;
          padding: 0;
          list-style: none;

          li {
            margin: 30px -2px 0;
            padding: 0;
            display: inline-block;
            width: 110px;
            text-align: center;
            cursor: pointer;

            .star {
              display: inline-block;
              width: 25px;
              height: 24px;
              background: url('../static/images/icons/star-on.svg') center top
              no-repeat;
            }

            span {
              display: block;
              color: $blue-grey;
              font-size: em(10px);
              font-weight: bold;
              font-stretch: condensed;
              letter-spacing: 1.8px;
              text-transform: uppercase;
            }

            &.active {
              .star {
                background-image: url('../static/images/icons/star-on.svg');
              }

              & ~ li .star {
                background-image: url('../static/images/icons/star-off.svg');
              }

              span {
                color: $blue-blue;
              }
            }
          }
        }
      }

      .field {
        margin-bottom: 50px;

        textarea {
          border-radius: 1px;

          ::placeholder {
            font-family: AvenirNext;
            color: $blue-grey;
            font-size: em(15px);
          }
        }
      }

      .eval {
        text-align: center;
        margin-bottom: 50px;
        padding: 30px 0;

        span {
          font-size: em(10px);
          font-weight: bold;
          font-style: normal;
          font-stretch: condensed;
          letter-spacing: 1.2px;
          color: $blue-grey;
          text-transform: uppercase;
        }

        ul {
          margin: 0 20px;
          padding: 0;
          list-style: none;
          display: inline-block;

          li {
            @include transition(border .2s ease, background .2s ease, color .2s ease );
            @include border-radius(50%);

            margin: 0 2px;
            padding: 0;
            display: inline-block;
            border: 1px solid $blue-grey;
            color: $blue-grey;
            text-align: center;
            width: 20px;
            line-height: 18px;
            font-size: em(10px);
            font-weight: 600;
            cursor: pointer;

            &:hover {
              color: $blue-blue;
              border-color: $blue-blue;
            }

            &.active {
              border-color: $blue-blue;
              background: $blue-blue;
              color: $white;
            }
          }
        }
      }

      .how-you-know {
        margin-bottom: 50px;
        padding: 15px 20px;

        ol {
          display: inline-block;
          text-align: left;
          margin: 0;
          padding: 0;
          list-style: none;

          li {
            margin: 0;
            padding: 5px 0;

            label {
              cursor: pointer;
              position: relative;
              padding: 0 0 0 26px;
              font-size: em(15px);
              color: $dark-blue;

              &:before {
                @include border-radius(50%);
                position: absolute;
                top: 0;
                left: 0;
                content: '';
                width: 14px;
                height: 14px;
                border: 1px solid $dark-blue;
                display: inline-block;
              }
            }

            input[type='checkbox']:checked + label:before {
              border-color: $blue-blue;
            }

            input[type='checkbox']:checked + label:after {
              position: absolute;
              top: 4px;
              left: 4px;
              content: '';
              width: 8px;
              height: 8px;
              background: $blue-blue;
              @include border-radius(50%);
            }

            input[type='checkbox'] {
              position: absolute;
              visibility: hidden;
            }

            input[type='text'] {
              width: 240px;
            }
          }
        }
      }
    }
  }

  .action {
    margin-top: 40px;
    text-align: center;

    a {
      margin: 30px 0 0;

      @media #{$phoneL} {
        display: block;
        margin: 20px;
      }
    }
  }
}

.popup-share-review {
  .to-copy {
    display: flex;
    align-items: center;
  }

  .submit {
    display: flex;
    flex-direction: column;
    margin-top: 20px;

    .left {
      margin-bottom: 20px;

      button {
        white-space: normal;
      }
    }

    .right {
      .Link {
        padding-bottom: 3px;
        font-size: 14px;
        border-bottom: 1px solid $blue-blue;

        &:hover {
          border-color: $dark-blue;
        }
      }
    }
  }
}
