.smartbanner-show {
  margin-top: 60px;

  header {
    top: 60px;

    nav {
      @media #{$tab-portrait} {
        top: 125px;
      }

      @media #{$phoneL} {
        top: 110px;
      }
    }
  }
}

.smartbanner-show .smartbanner {
  display: block;
}

/** Default **/
.smartbanner {
  position: fixed;
  left: 0;
  top: 0;
  display: none;
  width: 100%;
  height: 60px;
  z-index: 10000;
  -webkit-font-smoothing: antialiased;
  overflow: hidden;
  -webkit-text-size-adjust: none;
  background: $light-grey;
}

.smartbanner-container {
  margin: 0 auto;
  white-space: nowrap;
}

.smartbanner-close {
  display: inline-block;
  vertical-align: middle;
  margin: 0 10px;
  font-size: 20px;
  text-align: center;
  color: #888;
  text-decoration: none;
  border: 0;
  border-radius: 14px;
  -webkit-font-smoothing: subpixel-antialiased;
}

.smartbanner-close:active,
.smartbanner-close:hover {
  color: #aaa;
}

.smartbanner-icon {
  display: inline-block;
  vertical-align: middle;
  width: 50px;
  height: 50px;
  margin: 5px 12px 5px 0;
  background-size: cover;
  border-radius: 10px;
}

.smartbanner-info {
  display: inline-block;
  vertical-align: middle;
  width: 44%;
  font-size: em(10px);
  line-height: em(20px);
  color: $blue-grey;

  > div:not(.smartbanner-title) {
    display: none;
  }
}

.smartbanner-title {
  font-size: em(15px);
  font-weight: 600;
  color: $blue-blue;
}

.smartbanner-button {
  @include button;
  position: absolute;
  height: 35px;
  right: 20px;
  top: 0;
  bottom: 0;
  margin: auto 0;
  padding: 10px;
  font-size: em(10.5px);
  line-height: em(15px);
  letter-spacing: 0.88px;
  background-color: $light-grey;
  color: $blue-blue;
  border: 1px solid $blue-blue;
  font-weight: 600;

  &:hover {
    color: $dark-blue;
    border: 1px solid $dark-blue;
  }
}

.smartbanner-button:active,
.smartbanner-button:hover {
  color: #aaa;
}
