$gapColRows: 18px;

.bookingLogin {
  position: relative;
  width: 100%;
  max-width: 1160px;
  padding: 0 calc(#{$gapColRows} / 2);
  margin: 50px auto 0;
  column-gap: $gapColRows;
  border-spacing: $gapColRows 0;

  @media #{$tab} {
    display: block;
    width: 100%;
    border: 0 none;
    padding: 0;
  }

  .link {
    font-size: em(15px);
    color: $dark-blue;
    text-align: center;

    a {
      margin-left: 2px;
      color: $blue-blue;
      font-weight: 600;
    }
  }

  .form {
    @extend .clearfix;

    margin-bottom: 20px;
    text-align: center;

    &:last-of-type {
      border-bottom: 0 none;
      margin-bottom: 0px;
    }

    .field {
      width: calc((100% - 50px) / 2);
      padding: 0;
      float: left;
      margin: 0 50px 20px 0;
      text-align: left;

      &.full {
        width: 100%;
        margin-right: 0;
      }

      .reset-password {
        float: right;
        margin: 0;
        color: $blue-grey;
        font-size: em(12px);
        font-style: italic;
        transition: color 0.15s ease-in-out;

        &:hover {
          color: $blue-blue;
        }
      }

      label {
        display: inline-block;
        font-weight: 600;
        font-size: em(11px);
        margin: 0 0 0 15px;
        color: $dark-blue;
        text-transform: uppercase;
        letter-spacing: 1px;

        span {
          position: relative;
          float: right;

          svg {
            height: 15px;
            width: 15px;
            cursor: pointer;
            fill: $dark-blue;
          }

          em {
            @include translate(-50%, 0);

            display: none;
            position: absolute;
            left: 50%;
            background: $pale-grey;
            padding: 10px 20px;
            text-align: left;
            font-size: em(12px);
            font-style: normal;
            line-height: em(20px);
            width: 480px;
            bottom: 28px;
            text-transform: none;
            font-weight: normal;

            p {
              margin-bottom: 10px;
            }

            &:before {
              @include translate(-50%, 0);

              content: "";
              position: absolute;
              bottom: -7px;
              left: 50%;
              width: 0;
              height: 0;
              width: 0;
              height: 0;
              border-style: solid;
              border-width: 7px 7px 0 7px;
              border-color: $pale-grey transparent transparent transparent;
            }
          }

          &:hover {
            em {
              display: block;
            }
          }
        }

        &.error {
          color: $error;

          .input-label {
              color: $error;
          }
        }
      }

      &:nth-child(even) {
        margin-right: 0;
      }
    }

    input[type="submit"] {
      margin-top: 10px;
    }
  }

  .paymentOptions {
    text-align: center;

    span {
      display: inline-block;
      border: 0.5px solid $tertiary;
      border-radius: 25px;
      color: $tertiary;
      padding: 10px 15px 10px 50px;
      vertical-align: middle;
      background-position: 10px center;
      background-repeat: no-repeat;
      font-size: em(14px);
      font-weight: 600;
      cursor: pointer;

      &.creditCard {
        background-image: url("../static/images/icons/card-off.png");
      }

      &.useCredit {
        background-image: url("../static/images/icons/wallet-off.png");
      }

      &:hover {
        border: 0.5px solid $dark-blue;
        color: $dark-blue;
      }

      &.active {
        color: #fff;
        background-color: $primary;
        border-color: $primary;

        &.creditCard {
          background-image: url("../static/images/icons/card-on.png");
        }

        &.useCredit {
          background-image: url("../static/images/icons/wallet-on.png");
        }

        &:hover {
          border-color: $dark-blue;
          background-color: $dark-blue;
        }
      }
    }

    em {
      vertical-align: middle;
      font-style: normal;
      color: $blue-grey;
      margin: 0 35px;
      font-size: em(14px);
    }
  }

  .action {
    text-align: center;
  }

  .confirm {
    text-align: center;

    #CartPrice {
      display: inline-block;
      min-width: 400px;
      padding: 20px 30px;
      border: 1px solid $dark-blue;
      text-align: left;

      .price {
        color: $dark-blue;
        text-transform: uppercase;
        font-weight: bold;

        span {
          display: block;
          position: relative;
          font-size: em(15px);

          em {
            position: absolute;
            left: 50%;
            font-style: normal;
            font-size: em(20px);
          }
        }

        &:not(.active) {
          display: none;
        }
      }
    }
  }

  #LoginForm,
  #SignupForm {
    &:not(.active) {
      display: none;
    }
  }

  #LoginForm {
    form {
      max-width: 555px;
      margin: 0 auto;

      .form {
        padding-bottom: 20px;

        .field {
          width: 100%;
          margin: 0 0 20px 0;
        }
      }
    }
  }

  #SignupForm {
    .link {
      padding: 0 40px 10px;
      text-align: right;
    }

    .sponsorhsip {
      text-align: center;

      .field {
        float: none;
        display: inline-block;
        margin-right: 0;

        label {
          width: 100%;
          padding-right: 25px;
        }
      }
    }

    .action {
      padding-bottom: 40px;
    }
  }

  #CreditsCount {
    .field {
      margin-top: 20px;
      text-align: center;
    }

    strong {
      font-weight: 500;
      color: $blue-grey;
    }

    a {
      color: $tertiary;
      text-decoration: none;
    }
  }

  .booking-content-tabs {
    display: flex;
    margin: -25px -20px 20px -20px;

    @media #{$desktop} {
      margin: -40px -30px 25px -30px;
    }
  }

  .booking-content-tab {
    position: relative;
    color: #001875;
    text-align: center;
    text-transform: initial;
    font-weight: 700;
    letter-spacing: normal;
    flex: 1 1 100%;
    padding: 15px 0;
    cursor: pointer;
    border-bottom: 1px solid #b9c9d6;
    font-size: em(16px);

    @media #{$desktop} {
      font-size: em(20px);
    }

    &:nth-child(1) {
      border-right: 1px solid #b9c9d6;
    }

    &::after {
      content: ' ';
      position: absolute;
      bottom: -4px;
      left: 0;
      right: 0;
    }
    &:nth-child(1)::after {
      right: -1px;
    }

    &:hover::after {
      border: solid 2px #5ed6c466;
    }
    &.active::after {
      border: solid 2px #5ed6c4;
    }
  }

}

@media #{$phoneL} {
  .bookingLogin {
    .form .field {
      width: 100%;
      margin-right: 0;

      label span em {
        @include translate(calc(-100% + 20px), 0);
        width: 300px;

        &:before {
          left: auto;
          right: 6px;
        }
      }
    }

    .paymentOptions {
      span {
        display: block;
        width: 100%;
      }

      em {
        display: block;
        margin: 10px 0;
        text-align: center;
      }
    }

    .confirm #CartPrice {
      min-width: calc(100% - 20px);
      margin: 0 10px;
    }
  }
}
